import Splide from '@splidejs/splide';

const listMore = () => {
  const buttons = document.querySelectorAll('.p-moreButton');
  if (buttons.length < 1) {
    return;
  }

  Array.from(buttons).forEach((button) => {
    button.addEventListener('click', function (this: HTMLButtonElement) {
      const nextList = this.nextElementSibling;
      if (nextList?.getAttribute('aria-hidden') === 'true') {
        this.setAttribute('aria-hidden', 'true');
        nextList.setAttribute('aria-hidden', 'false');
        nextList.nextElementSibling?.setAttribute('aria-hidden', 'false');
      }
    });
  });
};

const seminarCarousel = () => {
  const carousel = document.querySelector('.p-seminarCarousel');
  if (carousel == null) {
    return;
  }

  const splide = new Splide('.p-seminarCarousel', {
    type: 'loop',
    padding: '25.68%',
    gap: '10%',
    focus: 'center',
    autoplay: true,
    breakpoints: {
      959: {
        padding: 0,
        gap: 0,
      },
    },
  });

  splide.mount({});
};

const handleRadioButtons = () => {
  const buttons = document.getElementById('js-seminarRadioButtons');
  if (buttons == null) {
    return;
  }

  const controlSeminarCondAreas = (state: 'hide' | 'show') => {
    const seminarCondAreas = document.querySelectorAll('.js-seminarCond');

    const action = {
      hide: () => {
        seminarCondAreas.forEach((area: any) => {
          area.setAttribute('aria-hidden', 'true');
          area.hidden = true;

          const checkboxes = area.querySelectorAll('input[type="checkbox"]');
          checkboxes.forEach((checkbox: HTMLInputElement) => {
            checkbox.checked = false;
          });
        });
      },
      show: () => {
        seminarCondAreas.forEach((area: any) => {
          area.setAttribute('aria-hidden', 'false');
          area.hidden = false;
        });
      },
    };

    return action[state]();
  };

  const handleClick = (e: { preventDefault: any; currentTarget: any }) => {
    const currentButton = e.currentTarget.getElementsByTagName('input');
    const hideCondTarget = ['t03', 't04'];
    if (hideCondTarget.includes(currentButton[0].value)) {
      controlSeminarCondAreas('hide');
    } else {
      controlSeminarCondAreas('show');
    }
  };

  Array.from(buttons.children).forEach((button) => {
    button.addEventListener('click', handleClick);
  });
};

export const Seminar = () => {
  seminarCarousel();
  listMore();
  handleRadioButtons();
};
