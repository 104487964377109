import Splide, { SlideComponent } from '@splidejs/splide';

const lpLoading = () => {
  const loading = document.getElementById('loading') as HTMLElement;
  const formContent = document.querySelector(
    '.p-Lp7Kv_form_content'
  ) as HTMLElement;
  const headerContent = document.querySelector('.header') as HTMLElement;
  if (!loading || !formContent || !headerContent) return;

  // MutationObserverの設定
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.addedNodes.length) {
        Array.from(mutation.addedNodes).forEach((node) => {
          if (
            node instanceof HTMLElement &&
            node.classList.contains('mktoFormRow')
          ) {
            // .mktoFormRowが追加された時にローディングを解除
            showContents();
          }
        });
      }
    }
  });

  // 監視の開始
  observer.observe(formContent, {
    childList: true,
    subtree: true,
  });

  setTimeout(() => {
    // loadingがdisplay:noneかどうか確認
    console.log(loading.style.display);
    if (loading.style.display === 'none') {
      return;
    }
    showContents();
  }, 3000);

  function showContents() {
    loading.style.opacity = '0';
    headerContent.style.zIndex = '3';
    observer.disconnect(); // 監視を停止
    setTimeout(() => {
      loading.style.display = 'none';
    }, 300);
  }
};

const caseCarousel = () => {
  const caseCarousel = document.querySelector('.js-caseCarousel');
  if (caseCarousel == null) {
    return;
  }

  const splideCase01 = new Splide('.js-caseCarousel', {
    autoHeight: true,
    pagination: false,
    // type: 'loop',
    // mediaQuery: 'max',
    // breakpoints: {
    //   959: {
    //     destroy: true,
    //   },
    // },
  });

  const tabItems = document.querySelectorAll('.p-Lp7CaseTab__item');
  // アクティブ時のカレント表示設定
  splideCase01.on('active', (slide: SlideComponent) => {
    const activeSlideName = slide.slide.getAttribute('data-slide');
    const activeTabElement = Array.from(tabItems).find((element) => {
      return element.getAttribute('data-tab') === activeSlideName;
    });
    for (let i = 0; i < tabItems.length; i++) {
      tabItems[i].classList.remove('-isActive');
    }
    if (activeTabElement) {
      activeTabElement.classList.add('-isActive');
    }
  });

  // タブクリック時の制御
  // スライドIDとタブの対応関係を定義
  const tabToSlideMap: Record<string, number> = {
    case01: 0,
    case02: 1,
    case03: 2,
    case04: 3,
    case05: 4,
    case06: 5,
  };
  tabItems.forEach((tabItem) => {
    tabItem.addEventListener('click', () => {
      const tabValue = tabItem.getAttribute('data-tab');
      if (tabValue && tabToSlideMap[tabValue] !== undefined) {
        splideCase01.go(tabToSlideMap[tabValue]);
      }
    });
  });

  splideCase01.mount({});
};

// partner_komon3ページのアコーディオン
const setupExpertsToggle = () => {
  const buttons = document.querySelectorAll('.js-experts-toggle');

  if (buttons.length > 0) {
    buttons.forEach((button) => {
      button.addEventListener('click', function () {
        const listItem = button.previousElementSibling as HTMLElement;
        if (listItem) {
          const currentHeight = listItem.offsetHeight;
          listItem.style.height = currentHeight + 'px';
          listItem.classList.add('--expanded');

          requestAnimationFrame(() => {
            const scrollHeight = listItem.scrollHeight;
            listItem.style.height = scrollHeight + 'px';

            listItem.addEventListener('transitionend', function onTransitionEnd() {
              listItem.style.height = 'auto';
              listItem.removeEventListener('transitionend', onTransitionEnd);
            });
          });

          (button as HTMLElement).style.display = 'none';
        }
      });
    });
  }
};

export const Lp = () => {
  lpLoading();
  caseCarousel();
  setupExpertsToggle();
};

