const loadAjax = () => {
  let ajaxurl: string =
    location.protocol +
    '//' +
    location.host +
    '/wp-admin/admin-ajax.php';
  // MoreボタンHTML
  let btn_html = document.getElementById('js-btn-more') as HTMLElement;

  // data属性値を取得
  const load = document.getElementById('js-load-more') as HTMLElement;
  let cur_cnt: number = parseInt(load.dataset.curCnt!, 10); // 表示済み件数
  const tgt_cnt: number = parseInt(load.dataset.tgtCnt!, 10); // Moreボタンで取得する件数
  const post_type = load.dataset.postType!; // 投稿タイプ
  const doc_type = load.dataset.docType!; // 投稿タイプ
  const doc_purpose = load.dataset.docPurpose!; // 投稿タイプ
  const doc_trend = load.dataset.docTrend!; // 投稿タイプ
  let all_cnt: number = parseInt(load.dataset.allCnt!, 10); // 投稿の全件数

  if (btn_html) {
    // クリックイベントのリスナー
    btn_html.addEventListener('click', function (e) {
      e.preventDefault();
      const cur_cnt_str: string = cur_cnt.toString();
      const tgt_cnt_str: string = tgt_cnt.toString();

      const body = new URLSearchParams({
        action: 'cf_ajax_get_more_post', //functions.phpで設定する関数名
        cur_cnt: cur_cnt_str,
        tgt_cnt: tgt_cnt_str,
        post_type: post_type,
      });
    
      if (doc_type) {
        body.append('doc_type', doc_type);
      }
    
      if (doc_purpose) {
        body.append('doc_purpose', doc_purpose);
      }
    
      if (doc_trend) {
        body.append('doc_trend', doc_trend);
      }
      // WP側の関数を実行
      //ajax処理。data{}のactionに指定した関数を実行、完了後はdoneに入る
      fetch(ajaxurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
      })
        .then(function (response) {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(function (data) {
          //cf_ajax_get_more_post関数で取得したデータがdataに入る
          //.loadにデータを追加
          load.insertAdjacentHTML('beforeend', data);
          //表示件数を増やす
          cur_cnt += tgt_cnt;
          //まだ全件表示されていない場合、ボタンを再度表示
          if (cur_cnt >= all_cnt) {
            // load.insertAdjacentHTML('afterend', '<button id="js-btn-more" class="c-button_more"><span>もっと見る</span></button>');
            // Moreボタンを削除
            btn_html.remove();
          }
        })
        .catch(function (error) {
          console.error(
            'There has been a problem with your fetch operation:',
            error
          );
          alert('エラーが発生しました');
        });
    });
  }
};

const handleRadioButtons = () => {
  const buttons = document.getElementById('js-whitepaperRadioButtons');
  if (buttons == null) {
    return;
  }

  const controlWhitepaperCondAreas = (state: 'hide' | 'show') => {
    const whitepaperCondAreas = document.querySelectorAll('.js-whitepaperCond');

    const action = {
      hide: () => {
        whitepaperCondAreas.forEach((area: any) => {
          area.setAttribute('aria-hidden', 'true');
          area.hidden = true;

          const checkboxes = area.querySelectorAll('input[type="checkbox"]');
          checkboxes.forEach((checkbox: HTMLInputElement) => {
            checkbox.checked = false;
          });
        });
      },
      show: () => {
        whitepaperCondAreas.forEach((area: any) => {
          area.setAttribute('aria-hidden', 'false');
          area.hidden = false;
        });
      },
    };

    return action[state]();
  };

  const handleClick = (e: { preventDefault: any; currentTarget: any }) => {
    const currentButton = e.currentTarget.getElementsByTagName('input');
    const hideCondTarget = ['t03'];
    if (hideCondTarget.includes(currentButton[0].value)) {
      controlWhitepaperCondAreas('hide');
    } else {
      controlWhitepaperCondAreas('show');
    }
  };

  Array.from(buttons.children).forEach((button) => {
    button.addEventListener('click', handleClick);
  });
};

export const Whitepaper = () => {
  handleRadioButtons();
  loadAjax();
};
