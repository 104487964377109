export const Contact = () => {
  accordion();
};

const accordion = () => {
  const btns = document.querySelectorAll('.js-accordion-btn');
  btns?.forEach((btn) => {
    btn.addEventListener('click', () => {
      const attr = btn.getAttribute('data-accordion');
      const content = document.querySelector(`.js-accordion-content[data-accordion="${attr}"]`);
      btn.classList.toggle('-active');
      content?.classList.toggle('-active');
    });
  })
}
