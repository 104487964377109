import MicroModal from 'micromodal';
import Splide from '@splidejs/splide';

const modal = () => {
  if (document.querySelector('.js-expertsModal')) {
    MicroModal.init();
  }
};

const expertsCarousel01 = () => {
  const carouselExperts01 = document.querySelector('.js-carousel01');
  if (carouselExperts01 == null) {
    return;
  }

  const splideExperts01 = new Splide('.js-carousel01', {
    type: 'loop',
    // padding: '25.68%',
    gap: '27px',
    focus: 'center',
    autoplay: true,
    breakpoints: {
      959: {
        padding: 0,
        gap: '30px',
      },
    },
  });

  splideExperts01.mount({});
};

const expertsCarousel02 = () => {
  const carouselExperts01 = document.querySelector('.js-carousel02');
  if (carouselExperts01 == null) {
    return;
  }

  const splideExperts02 = new Splide('.js-carousel02', {
    type: 'loop',
    // padding: '25.68%',
    gap: '27px',
    focus: 'center',
    autoplay: true,
    breakpoints: {
      959: {
        padding: 0,
        gap: '30px',
      },
    },
  });

  splideExperts02.mount({});
};

export const Experts = () => {
  modal();
  expertsCarousel01();
  expertsCarousel02();
};
