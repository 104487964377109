import { appLoader, Functions } from './appLoader';
import { Common } from './apps/Common';
import { Home } from './apps/Home';
import { Seminar } from './apps/Seminar';
import { Whitepaper } from './apps/Whitepaper';
import { Experts } from './apps/Experts';
import { Service } from './apps/Service';
import { Lp } from './apps/LP';
import { Contact } from 'apps/Contact';

const functions: Functions = {
  Common,
  Home,
  Seminar,
  Whitepaper,
  Experts,
  Service,
  Lp,
  Contact
};

const docReady = () => {
  appLoader(functions);
};

/**
 * Reference:
 * [jquery/src/core/ready.js](https://github.com/jquery/jquery/blob/main/src/core/ready.js)
 */

function completed() {
  document.removeEventListener('DOMContentLoaded', completed);
  window.removeEventListener('load', completed);
  docReady();
}

if (document.readyState !== 'loading') {
  // Handle it asynchronously to allow scripts the opportunity to delay ready
  window.setTimeout(docReady);
} else {
  // Use the handy event callback
  document.addEventListener('DOMContentLoaded', completed);
  // A fallback to window.onload, that will always work
  window.addEventListener('load', completed);
}
