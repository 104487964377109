import Splide from '@splidejs/splide';

const serviceTabs = () => {
  const tabBtn = document.querySelectorAll('.p-serviceTabs__tab');
  if (tabBtn.length == null) {
    return;
  }

  const onTabClick = (e: Element) => {
    const allContents = document.querySelectorAll('.p-serviceTabsContent__tab');
    const thisBtn = e;

    for (let i = 0; i < allContents.length; i++) {
      allContents[i].classList.remove('--current');
    }

    for (let i = 0; i < tabBtn.length; i++) {
      tabBtn[i].classList.remove('--current');
    }

    const content = '#' + thisBtn.getAttribute('data-tab');
    const show = document.querySelectorAll(content)[0];
    console.log(show);
    show.classList.add('--current');
    e.classList.add('--current');
  };

  for (let i = 0; i < tabBtn.length; i++) {
    tabBtn[i].addEventListener('click', (e: Event) => {
      e.preventDefault();
      onTabClick(tabBtn[i]);
    });
  }
};

const candidatesCarousel01 = () => {
  const boardCandidates01 = document.querySelector('.js-boardCandidates01');
  if (boardCandidates01 == null) {
    return;
  }

  const splideCandidates01 = new Splide('.js-boardCandidates01', {
    type: 'loop',
    mediaQuery: 'min',
    breakpoints: {
      959: {
        destroy: true,
      },
    },
  });

  splideCandidates01.mount({});
};

const candidatesCarousel02 = () => {
  const boardCandidates02 = document.querySelector('.js-boardCandidates02');
  if (boardCandidates02 == null) {
    return;
  }

  const splideCandidates02 = new Splide('.js-boardCandidates02', {
    type: 'loop',
    mediaQuery: 'min',
    breakpoints: {
      959: {
        destroy: true,
      },
    },
  });

  splideCandidates02.mount({});
};

const candidatesCarousel03 = () => {
  const boardCandidates03 = document.querySelector('.js-boardCandidates03');
  if (boardCandidates03 == null) {
    return;
  }

  const splideCandidates03 = new Splide('.js-boardCandidates03', {
    type: 'loop',
    mediaQuery: 'min',
    breakpoints: {
      959: {
        destroy: true,
      },
    },
  });

  splideCandidates03.mount({});
};

const candidatesCarousel04 = () => {
  const boardCandidates04 = document.querySelector('.js-boardCandidates04');
  if (boardCandidates04 == null) {
    return;
  }

  const splideCandidates04 = new Splide('.js-boardCandidates04', {
    type: 'loop',
    mediaQuery: 'min',
    breakpoints: {
      959: {
        destroy: true,
      },
    },
  });

  splideCandidates04.mount({});
};

export const Service = () => {
  serviceTabs();
  candidatesCarousel01();
  candidatesCarousel02();
  candidatesCarousel03();
  candidatesCarousel04();
};
