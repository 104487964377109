import SmoothScroll from 'smooth-scroll';
import { UseMediaQuery } from '../modules/useMediaQuery';
import MicroModal from 'micromodal';

// smooth-scroll非対応のブラウザ用
const fallbackScrollBehavior = () => {
  const isSmoothScrollSupported =
    'scrollBehavior' in document.documentElement.style;

  //scrollBehaviorをサポートしないブラウザでのみ発火
  //Safari14対応
  if (!isSmoothScrollSupported) {
    new SmoothScroll('a[href*="#"]', {
      speed: 500,
      durationMax: 1000,
      easing: 'easeInOutQuad',
      header: '.header',
    });
  }
};

// body固定
const fixedBody = () => {
  const html: HTMLElement | null = document.querySelector('html');
  const body: HTMLElement = document.body;
  const isFixed: boolean = body.getAttribute('style') ? true : false;

  // body固定
  const fixed = () => {
    // bodyが動かないように固定・位置を保存しておく
    const scrollPosition: string = `${window.pageYOffset}`;
    // scroll.value = scrollPosition
    body.dataset.scroll = scrollPosition;
    body.setAttribute(
      'style',
      `position: fixed; top: -${scrollPosition}px; width: 100%;`
    );
  };

  // 固定解除
  const release = () => {
    const scroll: number = Number(body.dataset.scroll);

    // もとのスクロール位置まで戻す
    document.body.removeAttribute('style');
    html?.setAttribute('style', 'scroll-behavior: inherit'); // smooth scroll解除
    window.scrollTo({ top: scroll });
    window.setTimeout(() => {
      html?.removeAttribute('style');
    }, 100);
  };

  isFixed ? release() : fixed();
};

// Header
// ヘッダーメニューの表示/非表示
const navToggle: () => void = () => {
  const button = document.querySelector('#js-nav-button');
  button?.addEventListener('click', () => {
    fixedBody();

    const id = button.getAttribute('aria-controls');
    const nav = document.querySelector(`#${id}`);

    if (button.getAttribute('aria-expanded') === 'false') {
      button.setAttribute('aria-expanded', 'true');
      nav?.setAttribute('aria-hidden', 'false');
    } else {
      button.setAttribute('aria-expanded', 'false');
      nav?.setAttribute('aria-hidden', 'true');
    }

    const body = document.body;

    if (body.classList.contains('-headerReverse')) {
      body.classList.toggle('-headerOpen');
    }
  });
};

// アコーディオン
const toggle = (element: Element) => {
  const id: string | null = element.getAttribute('aria-controls');

  const menu: Element | null = document.querySelector(`#${id}`);
  const menuHeight = Array.from(menu?.children || []).reduce(
    (acc, child) => acc + child.clientHeight,
    0
  );

  // ボタンの状態を切り替え
  if (element.getAttribute('aria-expanded') === 'false') {
    element.setAttribute('aria-expanded', 'true');
    menu?.setAttribute('aria-hidden', 'false');
    menu?.setAttribute('style', `height: ${menuHeight}px`);
  } else {
    element.setAttribute('aria-expanded', 'false');
    menu?.setAttribute('aria-hidden', 'true');
    menu?.removeAttribute('style');
  }
};

// ヘッダーメニュー内のアコーディオン
const navMenuToggle: () => void = () => {
  const button: NodeListOf<Element> =
    document.querySelectorAll('.js-menu-toggle');

  button.forEach((element: Element) => {
    element.addEventListener('click', () => {
      toggle(element);
    });
  });
};

new UseMediaQuery({
  mediaQueryString: '(min-width: 1190px)',
  sp: () => {
    console.log('sp');
    const header = document.querySelector('.header');
    const expanded: NodeListOf<HTMLElement> | undefined =
      header?.querySelectorAll('[aria-expanded="true"]');

    expanded?.forEach((item: HTMLElement) => {
      toggle(item);
    });

    const footer = document.querySelector('.footer');
    const footerMenu: NodeListOf<HTMLElement> | undefined =
      footer?.querySelectorAll('[aria-expanded="true"]');

    footerMenu?.forEach((item: HTMLElement) => {
      toggle(item);
    });
  },
  tablet: () => {
    // over 1190px
    const header = document.querySelector('.header');
    console.log('tablet');
    const body = document.body;
    const expanded: NodeListOf<HTMLElement> | undefined =
      header?.querySelectorAll('[aria-expanded="false"]');

    if (body.getAttribute('style')) {
      fixedBody();
      body.classList.remove('-headerOpen');
    }

    expanded?.forEach((item: HTMLElement) => {
      setTimeout(() => {
        toggle(item);
      }, 100);
    });

    const footer = document.querySelector('.footer');
    const footerMenu: NodeListOf<HTMLElement> | undefined =
      footer?.querySelectorAll('[aria-expanded="false"]');

    footerMenu?.forEach((item: HTMLElement) => {
      toggle(item);
    });
  },
});

// PCナビhover
const gNav = () => {
  const headerBg = document.querySelector('.js-header-bg');
  const nav = document.querySelector('.js-nav-menu');
  const item = nav?.querySelectorAll('.js-has-child');

  item?.forEach((element) => {
    element.addEventListener('mouseenter', () => {
      const menu = element.querySelector('.js-nav-inner');
      const active = nav?.querySelector('.js-nav-inner.is-active');
      headerBg?.classList.add('is-active');
      active?.classList.remove('is-active');
      menu?.classList.add('is-active');
      nav?.classList.add('is-active');
    });
  });

  item?.forEach((element) => {
    element.addEventListener('mouseleave', () => {
      const active = nav?.querySelector('.js-nav-inner.is-active');
      headerBg?.classList.remove('is-active');
      active?.classList.remove('is-active');
      nav?.classList.remove('is-active');
    });
  });
};

const pageTop = () => {
  const button = document.querySelector('#js-pageTop');

  button?.addEventListener('click', () => {
    window.scrollTo({ top: 0 });
  });
};

const modal = () => {
  const modalElement: HTMLElement | null = document.querySelector('.js-modal');
  if (modalElement) {
    MicroModal.init();
  }
};

const urlCopy = (): void => {
  const button: NodeListOf<HTMLButtonElement> =
    document.querySelectorAll('.js-copy');
  // buttonが存在しない場合は処理を終了
  if (!button) return;

  button.forEach((element: HTMLButtonElement) => {
    element.addEventListener('click', (): void => {
      // buttonのvalueを取得する
      const url: string = element.value;
      // クリップボードにコピーする
      navigator.clipboard.writeText(url);
      // コピーしたことをユーザーに知らせるメッセージを表示する
      const copyText: Element | null = element.nextElementSibling;
      copyText?.classList.add('-active');
      setTimeout((): void => {
        copyText?.classList.remove('-active');
      }, 3000);
    });
  });
};

const serviceFloat = () => {
  const floatEl = document.querySelector('.js-serviceFloat');
  const footerEl = document.querySelector('.footer');

  if (floatEl == null || footerEl == null) {
    return;
  }

  const elementInView = (el: Element, dividend = 1) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) / dividend
    );
  };

  const displayScrollElement = (el: Element) => {
    el.classList.add('--show');
  };

  const hideScrollElement = (el: Element) => {
    el.classList.remove('--show');
  };

  const handleScrollAnimation = () => {
    if (window.pageYOffset > 600) {
    }

    if (elementInView(footerEl, 1)) {
      hideScrollElement(floatEl);
    } else if (window.pageYOffset > 300) {
      displayScrollElement(floatEl);
    } else {
      hideScrollElement(floatEl);
    }
  };

  window.addEventListener('scroll', () => {
    handleScrollAnimation();
  });
};

const smoothScroll = (): void => {
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  const headerElement: HTMLElement | null = document.querySelector('header');
  const headerHeight: number | undefined = headerElement?.clientHeight;
  smoothScrollTrigger.forEach((trigger) => {
    trigger.addEventListener('click', (e: Event) => {
      e.preventDefault();
      const href = trigger.getAttribute('href');
      const targetElement = href
        ? document.getElementById(href.replace('#', ''))
        : null;
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const gap = headerHeight;
        let target;
        if (gap) {
          target = rect + offset - gap;
        }
        window.scrollTo({
          top: target,
          behavior: 'smooth',
        });
        console.log('scroll');
      }
    });
  });
};

const pageScroll = () => {
  const hash: string = window.location.hash;

  // ハッシュがある場合、スムーススクロールを行う
  if (hash) {
    // ヘッダーの要素を取得
    const headerElement: HTMLElement | null = document.querySelector('header');

    // ハッシュの要素を取得
    const targetElement: HTMLElement | null = document.querySelector(hash);

    // ヘッダー要素と目標要素が存在する場合にスクロールを行う
    if (headerElement && targetElement) {
      // ヘッダーの高さを取得
      const headerHeight: number = headerElement.clientHeight;

      // 目標要素の位置を取得
      const rect: ClientRect | DOMRect = targetElement.getBoundingClientRect();
      const elemTop: number = rect.top + window.pageYOffset;

      // ヘッダーの高さを引いた位置にスムーススクロール
      window.scrollTo({
        top: elemTop - headerHeight,
        behavior: 'smooth',
      });
    }
  }
};

export const Common = () => {
  fallbackScrollBehavior();
  navToggle();
  navMenuToggle();
  gNav();
  pageTop();
  modal();
  urlCopy();
  serviceFloat();
  smoothScroll();
  pageScroll();
};
